import React from "react";
import { Helmet } from "react-helmet";
import { ReactTypeformEmbed } from 'react-typeform-embed';

const Contact = () => {
    return (
        <>
            <Helmet>
                <title>Aurient | Contact Us</title>
            </Helmet>

            <div className="container">
                <ReactTypeformEmbed
                    style={{ position: "relative", height: "70vh", paddingTop: "20vh" }}
                    url={process.env.REACT_APP_TYPEFORM_CONTACT}
                />
            </div>
        </>
    );
};

export default Contact;
