import React from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";
import classNames from "classnames";
import Image from "../components/elements/Image";
import { SectionProps } from "../utils/SectionProps";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const About = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap center-content", "push-left");

  return (
    <section {..._.omit(props, "staticContext")} className={outerClasses}>
      <Helmet>
        <title>Aurient | About Us</title>
      </Helmet>
      <div className="container">
      <div className={innerClasses}>
          <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
            Mission
          </h1>
          <div className="container-sm">
            <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="200">
            <br />
              To create connected and human organizations.
            </p>
          </div>
        </div>
        <div className={innerClasses}>
          <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="400">
            Our Story
          </h1>
          <div className="container-sm">
            <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
            <br />
              In summer 2020, we felt disconnected from our internship companies, making us want to get out ASAP. 
              We thought that maybe this is why talent attrition was so high these days. 
              <br />
              <br />
              So we interviewed a total of 55 (and going) HR Professionals, Organizational Developers, and Talent Managers, and 
              discovered those in Talent Management to be overworked in conducting Mentorship Programmes.
              They shared that it was time consuming and labour intensive to organise surveys and feedback, 
              do manual mentor matching, and analyse key insights from programme data.
              <br />
              <br />
              To solve these problems, we created Aurient.
            </p>
          </div>
        </div>

        <div className={innerClasses}>
          <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
            Team
          </h1>

          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image">
                    <Image
                      src={require("./../assets/images/team_profile/soohan.jpg")}
                      alt="Features tile icon 01"
                      width={128}
                      height={128}
                      rounded={true}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h5 className="mt-0 mb-0">Son Soo Han</h5>
                  <p>
                    <i>Business Development</i>
                    <br/>
                    Ex-Accenture, SG Innovate Best Apprentice Nominee
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image">
                    <Image
                      src={require("./../assets/images/team_profile/lowen.jpg")}
                      alt="Features tile icon 01"
                      width={128}
                      height={128}
                      rounded={true}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h5 className="mt-0 mb-0">Low En</h5>
                  <p>
                    <i>Technology</i>
                    <br/>
                    Ex-Rakuten Viki, Web Applications Instructor
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image">
                    <Image
                      src={require("./../assets/images/team_profile/philip.jpg")}
                      alt="Features tile icon 01"
                      width={128}
                      height={128}
                      rounded={true}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h5 className="mt-0 mb-0">Philip Wee</h5>
                  <p>
                    <i>Customer Relations</i>
                    <br/>
                    Ex-AMD, Multiple prev venture projects

                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image">
                    <Image
                      src={require("./../assets/images/team_profile/daryll.jpg")}
                      alt="Features tile icon 01"
                      width={128}
                      height={128}
                      rounded={true}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h5 className="mt-0 mb-0">Daryll Wong</h5>
                  <p>
                    <i>Technology</i>
                    <br/>
                    Ex-SAP. Head of Partnerships JunctionX
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image">
                    <Image
                      src={require("./../assets/images/team_profile/stephen.jpg")}
                      alt="Features tile icon 01"
                      width={128}
                      height={128}
                      rounded={true}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h5 className="mt-0 mb-0">Stephen Alvin</h5>
                  <p>
                    <i>Product</i>
                    <br/>
                    Ex-Aztech, Multiple prev venture projects
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

About.propTypes = propTypes;
About.defaultProps = defaultProps;

export default About;
