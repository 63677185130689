import React from "react";
import { Grid, Typography, Divider, Box, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LeftDrawerItem from "./LeftDrawerItem";
import QuestionAnswerRoundedIcon from "@material-ui/icons/QuestionAnswerRounded";
import AlternateEmailRoundedIcon from "@material-ui/icons/AlternateEmailRounded";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import ArrowRightRoundedIcon from "@material-ui/icons/ArrowRightRounded";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import BorderColorRoundedIcon from "@material-ui/icons/BorderColorRounded";

const useStyles = makeStyles((theme) => ({
  leftDrawer: {
    backgroundColor: "#3F103F",
    // backgroundColor: "#5A219D",
    paddingLeft: 20,
    paddingRight:20
  },
  icon: {
    color: "white",
    opacity: 0.65,
    height: 22,
    width: 22,
  },
  dividerColor: {
    backgroundColor: "white",
    opacity: 0.2,
    marginLeft: -20,
    marginRight: -20,
  },
}));

function LeftDrawer(props) {
  const classes = useStyles();
  return (
    <Grid item className={classes.leftDrawer}>
      <br />
      <Grid container direction="row">
        <Typography variant="body1" style={{ color: "white", fontWeight:"bolder" }}>
          Aurient Co
        </Typography>
        <ArrowDropDownRoundedIcon className={classes.icon} />
        <Grid
          container
          direction="row-reverse"
          item
          xs
          style={{ marginRight: 20 }}
        >
          <BorderColorRoundedIcon className={classes.icon} />
        </Grid>
      </Grid>
      <br />
      <Divider classes={{ root: classes.dividerColor }} variant="middle" />
      <br />
      <LeftDrawerItem
        icon={<QuestionAnswerRoundedIcon className={classes.icon} />}
        label="All DMs"
      />
      <LeftDrawerItem
        icon={<AlternateEmailRoundedIcon className={classes.icon} />}
        label="Mentions & Reactions"
      />
      <LeftDrawerItem
        icon={<MoreVertRoundedIcon className={classes.icon} />}
        label="More"
      />
      <br />
      <LeftDrawerItem
        icon={<ArrowRightRoundedIcon className={classes.icon} />}
        label="Channels"
      />
      <LeftDrawerItem
        icon={<ArrowRightRoundedIcon className={classes.icon} />}
        label="Direct Messages"
      />
      <LeftDrawerItem
        icon={<ArrowRightRoundedIcon className={classes.icon} />}
        label="Apps"
      />

      <Box
        width="70%"
        // height="400px"
        style={{ display: "flex", alignItems: "center", marginLeft: 28 }}
      >
        <Typography variant="caption" style={{ color: "white" }}>
          {props.caption}
        </Typography>
      </Box>
    </Grid>
  );
}

export default LeftDrawer;
