import React from "react";
import {
  Grid,
  Hidden,
  Box,
  Divider,
  Container,
  Typography,
} from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import InterestScenario from "./scenarios/InterestScenario";
import IntroduceTeamScenario from "./scenarios/IntroduceTeamScenario";
import SharedInterestsScenario from "./scenarios/SharedInterestsScenario";
import HelpScenario from "./scenarios/HelpScenario";
import ProfessionalGrowthScenario from "./scenarios/ProfessionalGrowthScenario";
import EmptyPanel from "./components/EmptyPanel";
import bkg from "./assets/working_people.svg";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { ReactTypeformEmbed } from "react-typeform-embed";
// import SampleDashboardComponent from "../SampleDashboardComponent";
import HRDashboardScenario from "./scenarios/HRDashboardScenario";
import StartScenario from "./scenarios/StartScenario";

const slackTheme = createMuiTheme({
  typography: {
    fontFamily: ["Lato", "Inter"].join(","),

    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 13,
    },
    subtitle1: {
      //FOR BUTTONS
      fontSize: 16,
      fontWeight: "bold",
    },
    h3: {
      // FOR HEADINGS
      fontFamily: "Inter",
      fontSize: 32,
      fontWeight: "bold",
    },
    h4: {
      // FOR SUBHEADINGS
      fontFamily: "Inter",
      fontSize: 20,
    },
  },
});

function LoraSlackChatbotInterface() {
  const [name, setName] = React.useState("John");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const scenarios = [
    {
      label: "StartScenario",
      component: (
        <StartScenario name={name} setName={setName} nextHandler={handleNext} />
      ),
    },
    {
      label: "IntroduceTeamScenario",
      component: <IntroduceTeamScenario name={name} />,
    },
    {
      label: "SharedInterestsScenario",
      component: <SharedInterestsScenario name={name} />,
    },
    {
      label: "HelpScenario",
      component: <HelpScenario name={name} />,
    },
    {
      label: "ProfessionalGrowthScenario",
      component: <ProfessionalGrowthScenario name={name} />,
    },
    {
      label: "HRDashboardScenario",
      component: <HRDashboardScenario />,
    },
    {
      label: "TypeForm",
      component: (
        <Grid container alignContent="center">
          <ReactTypeformEmbed
            style={{
              position: "relative",
              height: "600px",
              paddingTop: "25px",
            }}
            url={process.env.REACT_APP_TYPEFORM_FEEDBACK}
          />
        </Grid>
      ),
    },
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = scenarios.length;

  return (
    <ThemeProvider theme={slackTheme}>
      <Hidden mdUp>
        <Grid container style={{ padding: 50 }}>
          <Typography variant="h4">Mobile Version is being built...</Typography>
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Grid container direction="column" alignContent="center">
          <Grid item xs={12}>
            <Box style={{ height: "525px", overflowY: "hidden" }}>
              <SwipeableViews
                SwipeableViews
                axis={"x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                // enableMouseEvents
              >
                {scenarios.map((scene, index) => {
                  if (activeStep == scenarios.length - 1) {
                    return <div key={scene.label}>{scene.component}</div>;
                  }

                  if (index != scenarios.length - 1) {
                    return <div key={scene.label}>{scene.component}</div>;
                  }

                  return null;
                })}
              </SwipeableViews>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <MobileStepper
              style={{
                backgroundColor: "#eceded",
                width: "100%",
                marginLeft: 50,
                marginRight: 50,
              }}
              steps={maxSteps}
              position="static"
              variant="dots"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="medium"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  {<KeyboardArrowRight />}
                </Button>
              }
              backButton={
                <Button
                  size="medium"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {<KeyboardArrowLeft />}
                  Back
                </Button>
              }
            />
          </Grid>
        </Grid>
      </Hidden>
    </ThemeProvider>
  );
}

export default LoraSlackChatbotInterface;
