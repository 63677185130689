import React from 'react';
import {Hidden, Grid } from '@material-ui/core';

function EmptyPanel() {

  return (  
    <Hidden smDown><Grid item xs="1" style={{minWidth:50}}></Grid></Hidden> 
  ) 
}

export default EmptyPanel;




