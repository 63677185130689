import React, { useState } from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import FlashOnIcon from "@material-ui/icons/FlashOn";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import StrikethroughSIcon from "@material-ui/icons/StrikethroughS";
import CodeIcon from "@material-ui/icons/Code";
import LinkIcon from "@material-ui/icons/Link";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";

import TextFieldsIcon from "@material-ui/icons/TextFields";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import SendIcon from "@material-ui/icons/Send";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "black",
    opacity: 0.5,
    height: 18,
    width: 18,
    marginLeft: 5,
    marginRight: 5,
  },
}));

function ChatInput(props) {
  const classes = useStyles();
  const [textField, setTextField] = useState("");
  return (
    <Grid container style={{padding:12}}>
      <Grid container style={{borderRadius: 5, backgroundColor: "#E5E5E5" }}>
        <Grid
          container
          style={{
            backgroundColor: "white",
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
        >
          <TextField
            disabled={props.disabled}
            id="outlined-multiline-static"
            label={`Send a message to ${props.channelName}`}
            // label={<Typography variant="body1">Send a message to {props.channelName}</Typography>}
            variant="outlined"
            style={{ width: "100%", color: "white" }}
            value={textField}
            onChange={(e) => setTextField(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                console.log("User entered: ", e.target.value);
                if (props.setTopic) {
                  props.setTopic(e.target.value);
                }
                setTextField("");
              }
            }}
          />
        </Grid>
        <Grid container style={{ margin: 5}}>
          <Grid container xs={7}>
            {/* <FlashOnIcon className={classes.icon} /> */}
            <FormatBoldIcon className={classes.icon} />
            <FormatItalicIcon className={classes.icon} />
            {/* <StrikethroughSIcon className={classes.icon} /> */}
            <CodeIcon className={classes.icon} />
            <LinkIcon className={classes.icon} />
            {/* <FormatListNumberedIcon className={classes.icon} /> */}
            <FormatListBulletedIcon className={classes.icon} />
            {/* <FormatQuoteIcon className={classes.icon} /> */}
          </Grid>
          <Grid container direction="row-reverse" xs={5}>
            <SendIcon className={classes.icon} />
            <AttachFileIcon className={classes.icon} />
            <InsertEmoticonIcon className={classes.icon} />
            <AlternateEmailIcon className={classes.icon} />
            {/* <TextFieldsIcon className={classes.icon} /> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ChatInput;
