import React from "react";
import { Helmet } from "react-helmet";
// import sections
import LoraSlackChatbotInterface from "../components/sections/demo/LoraSlackChatbotInterface";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Aurient | Product Demo</title>
      </Helmet>
      <div style={{ height: "80px" }} />
      <div className="container">
        {/* Small hardcoded way to accommodate because I dont want to touch the original repo of that chat interface */}
        <div style={{ marginLeft: "-25px", marginRight: "-25px" }}>
          {/* Demo Component Hidden */}
          {/* <LoraSlackChatbotInterface /> */}
          <h4 style={{ textAlign: "center", padding: "120px", color:"#7d7d7d"}}>
            Coming Soon: 
            August 2021
          </h4>
        </div>
      </div>
    </>
  );
};

export default Home;
