import React, { useState } from "react";
import { Grid, Fade, List, Box, Divider, Container } from "@material-ui/core";
import Message from "../components/Message";
import LeftHeader from "../components/LeftHeader";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// import NameContext from "./NameContext";


const useStyles = makeStyles((theme) => ({
  mainArea: {
    backgroundColor: "#FFFFFF",
  },
  icon: {
    color: "white",
    opacity: 0.65,
    height: 22,
    width: 22,
  },
  icon2: {
    color: "black",
    opacity: 0.65,
    height: 25,
    width: 25,
  },
  starIcon: {
    color: "black",
    opacity: 0.65,
    height: 20,
    width: 20,
  },
  dividerColor: {
    backgroundColor: "black",
    opacity: 0.5,
  },

  list: {
    width: "100%",
    maxHeight: "700px",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
  },
}));

function ChatInterface({
  header,
  subHeader,
  textInputs,
  channelName,
  setMsgInput,
  setTopic,
  nextHandler
}) {
  const classes = useStyles();

  return (
    <Grid container alignContent="center" alignItems="center">
      <Grid container item xs={12} md={5} style={{ padding: 25 }}>
        <Fade in={true} timeout={900}>
          <Grid container>
            <LeftHeader header={header} subHeader={subHeader} />
            <TextField
              id="outlined-multiline-static"
              label={"Enter your name"}
              variant="outlined"
              style={{ width: "100%", color: "white", marginTop:25 }}
              onChange={(e) => {
                setMsgInput(e.target.value);
                console.log(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  nextHandler()
                }
              }}
            />
          </Grid>
        </Fade>
      </Grid>
    </Grid>
  );
}

function StartScenario({ setName, nextHandler }) {
  const [msgInput, setMsgInput] = useState("");
  return (
    <>
      <Grid container style={{ height: "100%", width: "100%" }}>
        <ChatInterface
          header={"Thanks for trying out Lora"}
          subHeader={
            "LORA connects your new hire socially with the team and ensures that he has all he needs. Proceed to find out more about what Lora does!"
          }
          channelName="LORA, You (private)"
          setMsgInput={setName}
          nextHandler={nextHandler}
        />
      </Grid>
    </>
  );
}

export default StartScenario;
