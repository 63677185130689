import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  header: {
    textAlign:"left",
    padding:10,
  },
}));

function TextAboveButton(props) {
  const classes = useStyles();
  return (
    <Grid container className={classes.header}>
      <Typography variant="h4">{props.text}</Typography>
    </Grid>
  );
}

export default TextAboveButton;
