import React from "react";
import { Helmet } from "react-helmet";

import { ReactTypeformEmbed } from "react-typeform-embed";

const Subscribe = () => {
  return (
    <>
      <Helmet>
        <title>Aurient | Subscribe</title>
      </Helmet>
      <div className="container" style={{ textAlign: "center" }}>
        <div style={{ marginTop: "70px" }}>
        <iframe 
          src="https://docs.google.com/forms/d/e/1FAIpQLSfetZUd2k2uk8ECFFDWRyyFoezdsL-QJ4WH7XsVXrTONDXb6A/viewform?embedded=true" 
          width="640" 
          height="800" 
          frameborder="0" 
          marginheight="0" 
          marginwidth="0"
          scrolling="no"
          >
            Loading…
        </iframe>
        </div>
      </div>
    </>
  );
};

export default Subscribe;

/*
        <ReactTypeformEmbed
          style={{ position: "relative", height: "70vh", paddingTop: "20vh" }}
          url={process.env.REACT_APP_TYPEFORM_SUBSCRIBE}
        />


          <iframe
            src="https://ce4fyyt70e1.typeform.com/to/fU5lzVen"
            width="640"
            height="450"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Loading…
          </iframe>



*/
