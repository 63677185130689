import logo from "./assets/logo.svg";
// import HorizontalBarGraph from '../../HRDashboard/HorizontalBarGraph/HorizontalBarGraph.jsx'
import LineChart from "./LineChart/LineChart.jsx";
import happinessData from "./data/happiness2.data.js";
import lineGraphData from "./data/lineGraph.data.js";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

// import conversationsData from '../../data/conversations.data.js'
import ProfessionalForceGraph from "./ForceGraphs/ProfessionalForceGraph.jsx";
import professionalCommunicationData from "./data/professionalCommunication.data.js";
import SocialForceGraph from "./ForceGraphs/SocialForceGraph.jsx";
import socialCommunicationData from "./data/socialCommunication.data.js";
import HobbySocialGraph from "./ForceGraphs/HobbySocialGraph.jsx";
import socialHobbyData from "./data/socialHobby.data.js";
import JSONDisplay from "./JSONDisplay/JSONDisplay.jsx";
import NestedList from "./JSONDisplay/NestedList.jsx";
import summaryData from "./data/summary.data.js";
import MyD3Component from "./ReactD3Template/ReactD3Template.jsx";
import HorizontalBarGraph from "./HorizontalBarGraph/HorizontalBarGraph2.jsx";
import DataSlicer from ".//DataSlicer/DataSlicer.jsx";
import DataSlicerByKey from "./DataSlicer/DataSlicerByKey.jsx";
import ConversationProportionChart from "./PieChart/ConversationProportionChart.jsx";
import conversationproportionData from "./data/conversationproportion.data.js";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import Tooltip from '@material-ui/core/Tooltip';

import React, { useState, useEffect } from "react";

import * as d3 from "d3";

// NOTE TO LOWEN/STEPHEN - D3 VERSION IS SET AT 4.0.0

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        backgroundColor: "#F5F5F2",
    },
    control: {
        padding: theme.spacing(2),
    },
}));

export default function HRDashboard() {
    const classes = useStyles();

    const [happinessSlicedData, setHappinessSlicedData] = useState(happinessData);
    const [lineGraphSlicedData, setLineGraphSlicedData] = useState(lineGraphData);
    const [conversationsProportionsSlicedData, setConversationsProportionsSlicedData] = useState(conversationproportionData.filter(function (d) { return (d["Department"] == "All") }));

    return (
        <Container maxWidth='lg' style={{ marginTop: "20px", marginBottom: "20px" }}>

            <Grid container className={classes.root} maxWidth='lg' spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h3'>
                        HR Dashboard
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h4'>
                        This is the analytics that the HR will be able to see
                </Typography>
                </Grid>
                {/* <Grid item xs={12}>
                <Typography variant='h4'>
                        Interactions between employees
                </Typography>
                </Grid> */}
                <Grid container item lg={6} xs={12} spacing={2}>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item>
                            <Typography variant='h4'>
                                Conversations by Department
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title={
                                <React.Fragment>
                                    <Typography color="inherit">Conversation Network</Typography>
                                    <br></br>
                                    <Typography style={{ color: '#A6E22E' }}>See the conversations between your teams</Typography>
                                    <br></br>
                                    <Typography style={{ color: '#E6CD53' }}>Drag the balls to view links more clearly</Typography>
                                    <br></br>
                                    <Typography color="inherit">Lora identifies the people within and between departments that <b>need to work together</b>. The information gathered is presented below</Typography>
                                    <br></br>
                                    <Typography color="inherit">Links in <i style={{ color: 'blue' }}>blue</i> mean high levels of communication, whereas <i style={{ color: 'orange' }}>orange</i> means a lower level of communication</Typography>
                                    <br></br>
                                    <Typography color="inherit"><b>Lower communication is not necessarily bad</b>, because your colleagues could just be working really hard! Do check in on them and make sure they are okay</Typography>
                                </React.Fragment>}>
                                <InfoOutlinedIcon />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <SocialForceGraph
                                graphData={socialCommunicationData}
                                style={{ width: '100%', height: '400px' }}>
                            </SocialForceGraph>
                        </Paper>
                    </Grid>

                </Grid>
                <Grid container item lg={6} xs={12} spacing={2}>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item>
                            <Typography variant='h4'>
                                Conversations by Skillset
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title={
                                <React.Fragment>
                                    <Typography color="inherit">Conversation Network</Typography>
                                    <br></br>
                                    <Typography style={{ color: '#E6CD53' }}>Identifying knowledge gaps within a company</Typography>
                                    <br></br>
                                    <Typography color="inherit">The network below is the same as the first, but instead shows communication between those with <b>similar skills</b></Typography>
                                    <br></br>
                                    <Typography color="inherit">Are those with similar skills <i>sharing their knowledge</i>? Or is information that is <b>desperately needed</b> in another department <b>available already elsewhere</b>?</Typography>
                                </React.Fragment>}>
                                <InfoOutlinedIcon />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <ProfessionalForceGraph
                                graphData={professionalCommunicationData}
                                style={{ width: '100%', height: '400px' }}>
                            </ProfessionalForceGraph>
                        </Paper>
                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    {/* <Typography variant='h4'>
                        What people are interested in
                </Typography> */}
                </Grid>
                <Grid container item xs={12} spacing={1}>
                    <Grid item>
                        <Typography variant='h4'>
                            Hobbies
                            </Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title={
                            <React.Fragment>
                                <Typography color="inherit">What people are interested in</Typography>
                                <br></br>
                                <Typography style={{ color: '#E6CD53' }}>Planning meaningful events</Typography>
                                <br></br>
                                <Typography color="inherit">This shows the different interest levels within the company, making <b>planning cohesions</b> a breeze!</Typography>
                                <br></br>
                                <Typography color="inherit">Since this sample company has employees who like <i>E-Sports</i> and <i>swimming</i>, perhaps one of these could be your next bonding session!</Typography>
                            </React.Fragment>}>
                            <InfoOutlinedIcon />
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <HobbySocialGraph
                            graphData={socialHobbyData}
                            style={{ width: '100%', height: '400px' }}>
                        </HobbySocialGraph>
                    </Paper>
                </Grid>
                <Grid container item lg={6} xs={12}>
                    <Grid container item xs={12}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item>
                                <Typography variant='h4'>
                                    Conversations Generated
                            </Typography>
                            </Grid>
                            <Grid item>
                                <Tooltip title={
                                    <React.Fragment>
                                        <Typography color="inherit">How people are using Lora</Typography>
                                        <br></br>
                                        <Typography style={{ color: '#E6CD53' }}>Use the 'Department' dropdown to view the types of conversations taking place for different departments</Typography>

                                    </React.Fragment>}>
                                    <InfoOutlinedIcon />
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <DataSlicer
                                label='Department'
                                data={conversationproportionData}
                                factorCol='Department'
                                onSlicedDataChange={setConversationsProportionsSlicedData}
                                defaultFactor="All"></DataSlicer>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <ConversationProportionChart
                                graphData={conversationsProportionsSlicedData}
                                style={{ width: '100%', height: '500px' }}>
                            </ConversationProportionChart>

                        </Paper>
                    </Grid>

                </Grid>
                <Grid container item lg={6} xs={12}>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Grid container item xs={12} spacing={1}>
                                <Grid item>
                                    <Typography variant='h4'>
                                        Employee Engagement
                            </Typography>
                                </Grid>
                                <Grid item>
                                    <Tooltip title={
                                        <React.Fragment>
                                            <Typography color="inherit">How much are employees using Lora</Typography>
                                            <br></br>
                                            <Typography style={{ color: '#E6CD53' }}>Low employee engagement <b>does not</b> mean a bad employee</Typography>
                                            <br></br>
                                            <Typography color="inherit">Lora determines how engaged employees are via surveys and analytics of conversation volume</Typography>
                                            <br></br>
                                            <Typography color="inherit">The information here will be transparent to all employees and should <b>only</b> be used to improve workplace cohesion</Typography>
                                            <br></br>
                                            <Typography color="inherit">Aurient will also work closely with your company to ensure <b>no analysis of sensitive data is performed without permission</b> </Typography>
                                            <br></br>

                                        </React.Fragment>}>
                                        <InfoOutlinedIcon />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <DataSlicer label='Department' data={happinessData} factorCol='Department' onSlicedDataChange={setHappinessSlicedData}></DataSlicer>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <HorizontalBarGraph
                                style={{ width: '100%', height: '500px' }}
                                data={happinessSlicedData}>

                            </HorizontalBarGraph>
                        </Paper>
                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <Grid container item xs={12}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item>
                                <Typography variant='h4'>
                                    Conversations and Employee Retention
                            </Typography>
                            </Grid>
                            <Grid item>
                                <Tooltip title={
                                    <React.Fragment>

                                        <Typography style={{ color: '#A6E22E' }}>A good employee lost means <b>thousands of dollars wasted training an employee for your competitor</b></Typography>
                                        <br></br>
                                        <Typography style={{ color: '#E6CD53' }}>View the statistics for different departments</Typography>
                                        <br></br>
                                        <Typography color="inherit">This graph allows you to track the amount Lora saves you by keeping employees <b>engaged</b> and <b>socially connected</b></Typography>
                                        <br></br>
                                        <Typography color="inherit">Lora really reduced attrition for the Technology and Business Development departments, but finance was relatively unaffected. Perhaps it was because they were already doing really well! Way to go Susan!</Typography>


                                    </React.Fragment>}>
                                    <InfoOutlinedIcon />
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <DataSlicerByKey
                                label='Department'
                                data={lineGraphData}
                                factorDict={{
                                    "Technology": ["attrition_tech", "conversations_tech"],
                                    "Business Development": ["attrition_biz", "conversations_biz"],
                                    "Finance": ["attrition_fin", "conversations_fin"],
                                    "default": ["attrition", "conversations"]
                                }}
                                requiredKeys={['month']}
                                factorCol='Department'
                                onSlicedDataChange={setLineGraphSlicedData}></DataSlicerByKey>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <LineChart
                            attritionData={lineGraphSlicedData}
                            conversationsData={lineGraphSlicedData}
                            style={{ width: '100%', height: '400px' }}>
                        </LineChart>
                    </Paper>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                    <Grid item>
                        <Typography variant='h4'>
                            Overall Summary
                            </Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title={
                            <React.Fragment>
                                <Typography color="inherit">A summary of Lora's impact since its introduction to your company</Typography>
                                <br></br>
                                <Typography style={{ color: '#E6CD53' }}>Click the + icons to expand each field</Typography>
                                <br></br>

                            </React.Fragment>}>
                            <InfoOutlinedIcon />
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Box alignItems='center'>
                            <JSONDisplay
                                listName=""
                                data={summaryData}
                                style={{ width: '100%', height: '400px' }}>

                            </JSONDisplay>
                        </Box>

                    </Paper>
                </Grid>
            </Grid >
        </Container >
    );
}
