import React from "react";
import { Helmet } from "react-helmet";
// import sections
import Hero from "../components/sections/Hero";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import Testimonial from "../components/sections/Testimonial";
import Cta from "../components/sections/Cta";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Aurient | Landing Page</title>
      </Helmet>
      <Hero className="illustration-section-01" />
      <FeaturesTiles />
      {/* Hide Features */}
      {/* <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" /> */}
      <Cta split />
    </>
  );
};

export default Home;
