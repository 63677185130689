import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1,0),
        minWidth: 140,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function Slicer(props) {

    const classes = useStyles()
    const [selection, setSelection] = useState('')
    const [factorColVals, setFactorColVals] = useState([])


    const handleChange = (event) => {
        setSelection(event.target.value)

        //Get the filtered data
        let filteredData
        if (event.target.value == "") {
            //Check if there is a default value
            if (props.defaultFactor) {
                filteredData= props.data.filter(function (d) { return (d[props.factorCol] == props.defaultFactor) })
            } else {
                filteredData = props.data
            }

            
        } else {
            filteredData= props.data.filter(function (d) { return (d[props.factorCol] == event.target.value) })
        }

        if (props.onSlicedDataChange) {
            props.onSlicedDataChange(filteredData)
        }

    }

    const renderOptions = () => {
        
        //Get unique values of the factor column
        if (factorColVals && factorColVals.constructor === Array && factorColVals.length === 0) {
            setFactorColVals(
                [...props.data.reduce(
                    (acc, cur) => acc.add(cur[props.factorCol]),
                    new Set()
                )]
            )
        }

    }

    useEffect(() => {
        renderOptions()
    }, [props.data, props.factorCol, selection])

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">{props.factorCol}</InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selection}
                onChange={handleChange}
                label={props.label}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {factorColVals.map((factorColVal) => {
                    if (factorColVal != props.defaultFactor) 
                    return <MenuItem value={factorColVal}>{factorColVal}</MenuItem>
                }
                    
                )}
            </Select>
        </FormControl>
    )
}