import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  header: {
    textAlign:"left",
    padding:25,
  },
}));

function LeftHeader(props) {
  const classes = useStyles();
  return (

      <Grid container className={classes.header}>
        <Typography variant="h3" style={{marginBottom:12}}>{props.header}</Typography>
        <Typography variant="h4">{props.subHeader}</Typography>
      </Grid>

  );
}

export default LeftHeader;
