import React from "react";
import { Grid, Divider, Box, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChannelBar from "./ChannelBar";
import ChatInput from "./ChatInput";
import Message from "./Message";
import chatData from "./ChatData";
import { Scrollbars } from "react-custom-scrollbars";

const useStyles = makeStyles((theme) => ({
  mainArea: {
    backgroundColor: "#FFFFFF",
  },
  icon: {
    color: "white",
    opacity: 0.65,
    height: 22,
    width: 22,
  },
  icon2: {
    color: "black",
    opacity: 0.65,
    height: 25,
    width: 25,
  },
  starIcon: {
    color: "black",
    opacity: 0.65,
    height: 20,
    width: 20,
  },
  dividerColor: {
    backgroundColor: "black",
    opacity: 0.5,
  },

  list: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
  },
}));

function TalkArea({ textInputs, channelName, setMsgInput, setTopic, header, disabled }) {
  const classes = useStyles();

  return (
    <Grid item xs className={classes.mainArea}>
      <Grid container direction="column">
        <ChannelBar channelName={channelName} />
        <Box width="100%"><Divider/></Box>
          <Box height="300px" width="100%">
            <Scrollbars autoHeight={true} autoHeightMax={300}>
              <List className={classes.list}>{textInputs}</List>
            </Scrollbars>
          </Box>
        <Box borderRadius={5} width="100%">
          <ChatInput
            setMsgInput={setMsgInput}
            setTopic={setTopic}
            channelName={channelName}
            disabled={disabled}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default TalkArea;
