import React from 'react';
import classNames from 'classnames';
import Image from '../../elements/Image';


const FooterSocial = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-social',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <a href={process.env.REACT_APP_LINKEDIN_PAGE}>
            <Image
              src={require('./../../../assets/images/linkedin-icon.svg')}
              alt="Features tile icon 02"
              width={32}
              height={32} />
          </a>
        </li>

      </ul>
    </div >
  );
}

export default FooterSocial;