import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import { ContactsOutlined } from '@material-ui/icons';
import { ListItemSecondaryAction } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '600px',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

function updatePath(obj, path, value) {
    var parts = path;
    var i, tmp;
    for (i = 0; i < parts.length; i++) {
        tmp = obj[parts[i]];
        if (value !== undefined && i == parts.length - 1) {
            tmp = obj[parts[i]] = value;
        }
        else if (tmp === undefined) {
            tmp = obj[parts[i]] = {};
        }
        obj = tmp;
    }
    return obj;
}

export default function JSONDisplay(props) {
    const classes = useStyles();

    const generateRecursiveOpenState = (newJSONdata) => {
        var newData = {}
        Object.keys(newJSONdata).forEach(key => {
            newData[key] = false
        })
        return newData
    }

    const [JSONData, setJSONData] = useState(
        props.data
    )
    const [open, setOpen] = React.useState(generateRecursiveOpenState(props.data));

    const handleClick = (key) => {
        setOpen({ ...open, [key]: !open[key] })
    };

    const recursiveDisplayJSON = (newJSONData) => {
        return Object.keys(newJSONData).map((key, index) => {

            if (typeof (newJSONData[key]) === 'object' && newJSONData[key] !== null) {
                return (<>
                    <ListItem button onClick={() => handleClick(key)}>
                        <ListItemIcon>
                            <AddCircleOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary={key}>
                        </ListItemText>
                        {open[key] ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={open[key]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {recursiveDisplayJSON(newJSONData[key])}
                    </List>
                    </Collapse>
                    </>
                )
            } else if (typeof (newJSONData[key]) !== 'object') {
                //If result is not an object return this
                return (

                    <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText>{key}</ListItemText>
                        <ListItemSecondaryAction>
                            <ListItemText>
                                {(newJSONData[key])}
                            </ListItemText>
                        </ListItemSecondaryAction>
                    </ListItem>
                )
            }
        })
    }

    return (
        <List
            style={{margin:'auto'}}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    {props.listName}
        </ListSubheader>
            }
            className={classes.root}
        >
            {recursiveDisplayJSON(JSONData)}
        </List>
    )
}