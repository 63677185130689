import React from 'react';
import { Grid, Typography, Box, Divider} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';

const useStyles = makeStyles((theme) => ({

    paper:{
        marginBottom:12, marginTop:12, marginLeft: 20, marginRight:0,
    },

    icon2:{
        color:"black", 
        opacity:0.65,
        height:25,
        width:25
    },
    starIcon:{
        color:"black", 
        opacity:0.65,
        height:20,
        width:20
    },

}));

function ChannelBar(props) {
    const classes = useStyles();
    return (
        <Grid container className={classes.paper}>
            <Grid container xs={6} spacing={2}>
                <Grid item >
                    <Grid item xs={12}>
                        <Typography variant="body1" style={{color:"black", fontWeight:"bold"}}>{props.channelName}</Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant="body2" style={{color:"black", opacity:0.5}}>Add a topic</Typography>
                    </Grid>
                </Grid>
                {/* <Grid item>
                    <StarBorderRoundedIcon className={classes.starIcon}/>
                </Grid> */}
            </Grid>
            <Grid container xs={6} direction="row-reverse" spacing={2}>
                <Grid item style={{display:"flex", alignItems:"center"}}>
                    <InfoOutlinedIcon className={classes.icon2}/>
                </Grid>
                <Grid item style={{display:"flex", alignItems:"center"}}>
                    <PersonAddOutlinedIcon className={classes.icon2}/>
                </Grid>
            </Grid>
        </Grid>
  )
}

export default ChannelBar




